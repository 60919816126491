import Rails from "@rails/ujs"

require("@hotwired/turbo-rails")
// require("channels")

require("stylesheets/tailwind/before.scss")
require("stylesheets/application.scss")
require("stylesheets/tailwind/after.scss")

import "controllers"

Rails.start()

// Rails engine for cache-friendly, client-side local time
// https://github.com/basecamp/local_time
import LocalTime from "local-time"
LocalTime.config.i18n["en"] = {
  date: {
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    abbrDayNames: [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    abbrMonthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    yesterday: "yesterday",
    today: "today",
    tomorrow: "tomorrow",
    on: "on {date}",
    formats: {
      default: "%b %e, %y",
      thisYear: "%b %e"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "a {time}",
    singularAn: "an {time}",
    elapsed: "{time} ago",
    second: "second",
    seconds: "seconds",
    minute: "minute",
    minutes: "minutes",
    hour: "hour",
    hours: "hours",
    formats: {
      default: "%l:%M%P"
    }
  },    
  datetime: {
    at: "{date} at {time}",
    formats: {
      default: "%B %e, %Y at %l:%M%P %Z"
    }
  }
}
LocalTime.config.locale = "en"
LocalTime.start()

document.addEventListener("turbo:load", function() {
  document.querySelectorAll("input[type=checkbox][name=record_id]").forEach(function(checkbox) {
    checkbox.addEventListener("change", function(event) {
      Rails.ajax({
        url: event.target.closest("ul").dataset.selectionUrl,
        type: "PUT",
        data: new URLSearchParams({ record_id: event.target.value, checked: event.target.checked }).toString()
      })
    })
  })
})