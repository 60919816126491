import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { tag: String, src: String } 
  static classes = [ "popup" ]

  show(e) {
    e.preventDefault();

    let modalHtml = document.getElementById("modal-frame-template").innerHTML
  
    document.querySelector("body").insertAdjacentHTML(
      'beforeend', 
      modalHtml.replace(/TURBO_FRAME_ID/g, this.turboFrameId).replace(/MODAL_TITLE/g, this.modalTitle).replace(/MODAL_POPUP_CLASSES/g, this.popupClasses.join(" "))
    )
    document.querySelector(`turbo-frame[id=${this.turboFrameId}]`).setAttribute("src", this.element.href)
  }

  get turboFrameId() {
    return this.tagValue || "modal-content"
  }

  get modalTitle() {
    return this.element.title || this.element.innerText
  }
}