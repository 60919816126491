import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: String }

  connect() { }

  refresh(e) {
    let turboFrame = document.getElementById(this.idValue)
    
    if (e.target.value) {
      turboFrame.setAttribute("src", `/subjects/${e.target.value}/phone_calls/assistance`)
    } else {
      turboFrame.setAttribute("src", turboFrame.dataset.fallback)
    }
  }
}