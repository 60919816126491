import { Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['input']

  connect() {
    new autoComplete({
      debounce: 300,
      searchEngine: "loose",
      selector: `#${this.inputTarget.id}`,
      threshold: 2,
      
      data: {
        src: async () => {
          const response = await get(`/people/autocomplete?term=${encodeURI(this.inputTarget.value)}`, { responseKind: "json" });
          if (response.ok) {
            const data = await response.json;
            return data;
          };
        },
        keys: ["label"],
        cache: false
      },
      
      resultsList: {
        maxResults: 10
      },
      resultItem: {
        highlight: true,
      },

      events: {
        input: {
          selection: (event) => {
            Turbo.visit(`/leads/contact/new?person_id=${event.detail.selection.value.value}`)
          }
        }
      }
    });
  }
}