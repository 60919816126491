import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "fieldset"]

  connect() {
    const selectedFieldsetValue = this.selectTarget.options[this.selectTarget.selectedIndex].value;
    
    this.fieldsetTargets.forEach((fieldset, index) => {  
      if (fieldset.id != selectedFieldsetValue) {
        fieldset.setAttribute("disabled", true)
        fieldset.setAttribute("hidden", true)
      }
    });
  }

  change() {
    const selectedFieldsetValue = this.selectTarget.options[this.selectTarget.selectedIndex].value;

    this.fieldsetTargets.forEach((fieldset, index) => {  
      if (fieldset.id == selectedFieldsetValue) {
        fieldset.removeAttribute("disabled")
        fieldset.removeAttribute("hidden")
      } else {
        fieldset.setAttribute("disabled", true)
        fieldset.setAttribute("hidden", true)
      }
    });
  }
}