import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "due", "dueDate", "title", "item", "workflow", "sortHandle" ]

  connect() {
    // if on workflow#show
    if (location.pathname.match(/\w+\/\d+\/workflows\/\d+/)) {
      if (this.hasWorkflowTarget) {
        this.workflowTarget.classList.add("hidden")
      }
      this.sortHandleTarget.classList.remove("hidden")
    }

    // if on tasks#show
    if (location.pathname.match(/\w+\/\d+\/tasks\/\d+/)) {
      if (this.hasWorkflowTarget) {
        this.workflowTarget.classList.add("hidden")
      }
    }

    // show source if on reports page
    if (location.pathname.match(/reports\/tasks\/\w+/)) {
      this.itemTarget.classList.remove("hidden")
      if (this.hasWorkflowTarget) {
        this.workflowTarget.classList.add("hidden")
      }  
    }

    if (this.hasDueTarget && this.isOpenOrActive() && this.isOverdue()) {
      this.dueTarget.classList.add("text-red-500")
    }
  }

  isOpenOrActive() {
    return (this.element.classList.contains("-todo"))
  }
  
  isOverdue() {
    return (new Date(this.dueDateTarget.getAttribute("datetime")) < new Date().setHours(0,0,0,0))
  }
}