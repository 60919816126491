import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import autosize from "autosize"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["searchField", "searchValue", "searchFieldset", "personFieldset"]

  connect() {
    if (this.searchFieldTarget) {
      new autoComplete({
        searchEngine: "loose",
        selector: `#${this.searchFieldTarget.id}`,
        threshold: 2,
        debounce: 300,

        data: {
          src: async () => {
            const response = await get(`/people/autocomplete?term=${encodeURI(this.searchFieldTarget.value)}`);
            if (response.ok) {
              const data = await response.json;
              if (data.length == 0) {
                return [ { label: `Create "${this.searchFieldTarget.value}"`, value: null } ]
              } else {
                return data;
              }
            }
          },
          keys: ["label"],
          cache: false
        },
        
        resultsList: {
          maxResults: 10
        },

        resultItem: {
          highlight: true,
        },

        events: {
          input: {
            selection: (event) => {
              if (event.detail.selection.value.value == null) {
                this.searchFieldsetTarget.classList.add("hidden");
                this.personFieldsetTarget.classList.remove("hidden");
              
                this.personFieldsetTarget.querySelector("input").value = this.searchFieldTarget.value;

                window.setTimeout( () => {
                  this.personFieldsetTarget.querySelector("input[type=email]").focus();
                  autosize(this.personFieldsetTarget.querySelector("textarea"));
                }, 0);
              } else {
                this.searchFieldTarget.value = event.detail.selection.value.label;
                this.searchFieldTarget.disabled = true;
                this.searchValueTarget.value = event.detail.selection.value.value;

                this.searchFieldTarget.blur();
              }
            }
          }
        }    
      });
    }
  }
}