import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import { enter, leave } from "el-transition"; 

export default class extends Controller {
  static targets = [ "toggle", "menu" ]
  static values = { remoteUrl: String }

  connect() {
    this.isLoaded = !this.hasRemoteUrlValue

    useClickOutside(this)
  }

  clickOutside() {
    leave(this.menuTarget, "dropdown")
  }

  toggle() {
    if (this.menuTarget.classList.contains("hidden")) {
      enter(this.menuTarget, "dropdown")
      if (!this.isLoaded) {
        fetch(this.remoteUrlValue, {})
          .then(response => response.text())
          .then(html => {
            this.isLoaded = true
            this.menuTarget.innerHTML = html
          })
      }
    } else {
      leave(this.menuTarget, "dropdown")
    }
  }
} 