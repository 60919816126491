import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  showAssigns(event) {
    this.data.set('insertable', event.target.dataset.insertable)

    $(this.modalTarget).modal('show')
  }

  insert(event) {
    let value = event.target.closest('li').dataset.assign;

    let textArea = document.getElementById(this.data.get('insertable'));

    var startPos = textArea.selectionStart,
        endPos = textArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = textArea.value;

    if (value === null) {
        return;
    }

    // insert:
    textArea.value = tmpStr.substring(0, startPos) + value + tmpStr.substring(endPos, tmpStr.length);

    textArea.focus();

    // move cursor:
    setTimeout(() => {
      cursorPos += value.length;
      textArea.selectionStart = textArea.selectionEnd = cursorPos;

    }, 100);

    $(this.modalTarget).modal('hide')
  }
}