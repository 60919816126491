import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"; 

export default class extends Controller {
  connect() {
    enter(this.element, "flash")
  }

  dismiss() {
    leave(this.element, "flash")
  }

  disconnect() {
    this.element.remove()
  }
}