import { Controller } from "@hotwired/stimulus"
import { current } from "../helpers/current"

export default class extends Controller {
  static targets = [ "markAsRead" ]
  static values = { mentionedUsernames: Array }

  connect() {
    if (this.mentionedUsernamesValue.includes(current.account.username)) {
      this.markAsReadTarget.classList.remove('hidden')
    }
  }

  considerRead() {
    this.markAsReadTarget.setAttribute("disabled", true)
    this.markAsReadTarget.classList.add("text-green-500", "cursor-default")
    this.markAsReadTarget.querySelector("span").innerText = "Read"
  }
}