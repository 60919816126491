import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  renderOptions(event) {
    event.preventDefault()

    fetch(this.data.get("endpoint"), {})
      .then(response => response.text())
      .then(html => {
        document.querySelector("body").insertAdjacentHTML('beforeend', html)
      })
  }
}