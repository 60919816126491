import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["roleSelect", "otherRoleInput"]

  roleChanged(event) {
    let roleSelect = event.target
    let roleValue = roleSelect.options[roleSelect.selectedIndex].value
    if (roleValue == "other") {
      this.otherRoleInputTarget.parentElement.classList.remove("hidden")
      this.otherRoleInputTarget.focus() 
    } else {
      this.otherRoleInputTarget.parentElement.classList.add("hidden")
    }
  }
}