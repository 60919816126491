import { Controller } from "@hotwired/stimulus"

const RADIO_SELECTION_TOGGLE = 'radio-fieldset-selection-toggle'

export default class extends Controller {
  static targets = ["radioButton", "fieldsetContent"]
  
  connect() {
    if (this.radioButtonTarget.checked) {
      this.checked()
    } else {
      this.unchecked()
    }

    document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
  }

  toggle(event) {
    event.stopImmediatePropagation()

    const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value
      }
    });

    document.dispatchEvent(selectionEvent);
    return false;
  }

  handleSelection(event) {
    if (event.detail.name === this.radioButtonTarget.name) {
      if (this.radioButtonTarget.checked) {
        this.checked()
      } else {
        this.unchecked()
      }
    }
  }

  checked() {
    this.fieldsetContentTarget.removeAttribute("disabled")
    this.fieldsetContentTarget.removeAttribute("hidden")
  }

  unchecked() {
    this.fieldsetContentTarget.setAttribute("disabled", true)
    this.fieldsetContentTarget.setAttribute("hidden", true)
  }
}