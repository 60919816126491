import { Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['input']
  static values = { type: Array, endpointUrl: String }

  connect() {
    this.inputTarget.focus()
    
    new autoComplete({
      debounce: 300,
      searchEngine: "loose",
      selector: `#${this.inputTarget.id}`,
      threshold: 2,

      data: {
        src: async () => {
          const response = await get(`/dashboard/search/new?term=${encodeURI(this.inputTarget.value)}`);
          if (response.ok) {
            const data = await response.json;
            return data;
          };
        },
        keys: ["name", "description"],
        cache: false
      },
           
      resultsList: {
        maxResults: 10,
      },

      resultItem: {
        element: (item, data) => {
          item.innerHTML = `${data.value.type} - <span class="font-semibold">${data.value.name}</span> ${data.value?.description}`;
        }
      },

      events: {
        input: {
          selection: (event) => {
            Turbo.visit(`/dashboard/searches/${event.detail.selection.value.gid}`)
          }
        }
      }
    });
  }
}