import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static classes = ["current"]

  connect() {
    this.currentTab = this.linkTargets.find((link) => {
      return link.pathname.startsWith(window.location.pathname.slice(0, 5))
    });

    if (this.currentTab) {
      this.currentTab.classList.add(this.currentClass)
    }
  }

  linkTargetConnected(target) {
    if (!window.location.pathname == "/") {
      if (target.pathname.startsWith(window.location.pathname.slice(0, 5))) {
        target.classList.add(this.currentClass)
      } else {
        target.classList.remove(this.currentClass)
      }
    }  
  }

  highlight(event) {
    this.currentTab.classList.remove(this.currentClass)

    this.currentTab = event.currentTarget
    this.currentTab.classList.add(this.currentClass)
  }
}