import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    this.isLoaded = false
    this.tribute = new Tribute({
      lookup: "username",
      values: [
        { username: "team", first_name: "@team", last_name: "" },
        { username: "directors", first_name: "@directors", last_name: "" },
        { username: "agents", first_name: "@agents", last_name: "" },
        { username: "coordinators", first_name: "@coordinators", last_name: "" },
        { username: "support", first_name: "@support", last_name: "" },
      ],
      selectClass: "bg-blue-200",
      containerClass: "tribute-container rounded-md shadow ring-2 ring-blue-200 bg-white text-blue-900 my-3 z-20",
      itemClass: "px-4 py-2",
      menuItemLimit: 10,

      selectTemplate: function (item) {
        return '@' + item.original.username;
      },

      menuItemTemplate: function (item) {
        return item.original.first_name.concat(" ", item.original.last_name);
      }
    })

    this.tribute.attach(this.textareaTarget)
  }

  disconnect() {
    this.tribute.detach(this.textareaTarget)
  }

  load() {
    if (!this.isLoaded) {
      fetch("/autocomplete/accounts.json", {})
        .then(response => response.json())
        .then(json => this.tribute.append(0, json));
    }

    this.isLoaded = true
  }
}