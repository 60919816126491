import { Controller } from "@hotwired/stimulus"

let sortBy = require('lodash.sortby');

export default class extends Controller {
  connect() {
    let list = this.element

    sortBy(this.element.children, 
      [ function(childEl) { return childEl.dataset.position; } ]
    ).forEach(function(el) {
      list.appendChild(el)
    })
  }
}