import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { accountId: Number, assigneeId: Number }
  static targets = [ "subject", "subjectLink" ]

  connect() {
    if (this.hasSubjectTarget) {
      if (location.pathname.indexOf(this.subjectLinkTarget.pathname) > -1) {
        this.subjectTarget.classList.add("md:hidden")
      }
    }
  }
}