import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { accountId: Number, assigneeId: Number }
  static targets = [ "noteFooter", "noteSubjectLink", "noteContextLink" ]

  connect() {
    if (location.pathname.indexOf(this.noteSubjectLinkTarget.pathname) > -1) {
      this.noteFooterTarget.classList.add("md:hidden")
    }
  }
}