import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "content", "disable"]

  connect() {
    this.toggleContent()
  }

  toggleContent() {
    if (this.inputTarget.checked) {
      this.contentTarget.classList.remove("opacity-25");
      this.disableTargets.forEach(el => el.removeAttribute("disabled"));
    } else {
      this.contentTarget.classList.add("opacity-25");
      this.disableTargets.forEach(el => el.setAttribute("disabled", true));
    }
  }
}