import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    this.isLoaded = false
    this.tribute = new Tribute({
      trigger: "#",
      lookup: "name",
      values: [],
      selectClass: "bg-gray-200",
      containerClass: "tribute-container bg-white shadow-lg my-2 z-20",
      itemClass: "px-4 py-3",

      selectTemplate: function (item) {
        return "#" + item.original.name;
      },

      menuItemTemplate: function (item) {
        return "#" + item.original.name;
      }
    })
    this.tribute.attach(this.textareaTarget)
  }

  disconnect() {
    this.tribute.detach(this.textareaTarget)
  }

  load() {
    if (!this.isLoaded) {
      fetch("/autocomplete/hashtags.json", {})
        .then(response => response.json())
        .then(json => this.tribute.append(0, json));
    }

    this.isLoaded = true
  }
}