import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "button", "label"]

  connect() {
    if (!navigator.clipboard && !window.isSecureContext) {
      this.element.querySelector('button').classList.add("hidden")
    }
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value)

    if (this.hasLabelTarget) {
      this.labelTarget.innerText = "Copied"
    }
  }
}