import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["menuWrapper", "menuOverlay", "menuSidebar"]

  connect() {
  }

  openMenu() {
    this.menuWrapperTarget.style.removeProperty("display")

    enter(this.menuOverlayTarget, "sidebar")
    enter(this.menuSidebarTarget, "sidebar")
  }

  closeMenu() {    
    leave(this.menuSidebarTarget, "sidebar")
    leave(this.menuOverlayTarget, "sidebar").then(() => {
      this.menuWrapperTarget.style.setProperty("display", "none")
    })
  }

  async trackPrinting() {
    post("/analytics", {
      query: { printing: window.location.pathname }
    });
  }
}