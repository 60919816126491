import { Controller } from "@hotwired/stimulus"
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { post } from '@rails/request.js'

export default class extends Controller {
  connect() {
    const guide = driver({
      overlayOpacity: 0.5,
      showProgress: true,
      onDestroyed: (el, step) => {
        console.log("DESTROYED GUIDE"),

        post("/account/dismiss", {
          body: { name: "guide_project_tasks", metadata: { step: step.element } }
        });
      },

      steps: [
        { element: '#guide--tasks-card',
          popover: {
            title: 'New Tasks Section',
            description: 'Have clear visibility for on-hold, active and completed tasks.',
            position: 'top-center'
          }
        },

        { element: '#guide--add-new-task',
          popover: {
            title: 'Add Standalone Tasks',
            description: 'You can Assign & Schedule tasks without workflows.',
            position: 'top-center'
          }
        },

        { element: '#guide--assigned-tasks-filter',
          popover: {
            title: 'New Task Filters',
            description: 'Quickly see what is assigned to you or tasks you assigned to somebody else.',
            position: 'top-center'
          }
        },

        { element: '#guide--workflows-card',
          popover: {
            title: 'Same Workflows, but better',
            description: 'Get progress overview across all workflows in percentage or number of completed tasks.',
            position: 'left-center'
          }
        },

        { element: '#guide--import-workflow-template',
          popover: {
            title: 'Import Workflows from a Template',
            description: 'Improved template preview when importing single workflow.',
            position: 'left-center'
          }
        },
      ]  
    })

    guide.drive();
  }
}