import { Controller} from "@hotwired/stimulus"
import { leave } from "el-transition"; 

export default class extends Controller {
  static values = { delay: Number }

  connect() {
    this.timeout = setTimeout(this.remove, this.delayValue)
  }

  disconnect() {
    clearTimeout(this.timeout)

    this.remove()
  }

  remove = () => {
    leave(this.element, "flash").then(() => {
      this.element.remove()
    })
  }
}
