import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"; 

export default class extends Controller {
  static targets = [ "backdrop", "popup" ]

  connect() {
    enter(this.backdropTarget, "modal-backdrop")
    enter(this.popupTarget, "modal-popup")
  }

  close(e) {
    e.preventDefault()

    if (this.element.querySelector("form")) {
      if (confirm("Are you sure?")) {
        this.hideModal()  
      }
    } else {
      this.hideModal()
    }
  }

  discard(e) {
    e.preventDefault()
    this.hideModal()
  }

  closeWithEscape(e) {
    if (e.keyCode === 27) {
      this.close(e);
    }
  }

  submitEnd(e) {
    if (e.detail.formSubmission.result.success) {
      this.hideModal()
    }
  }

  hideModal() {
    leave(this.popupTarget, "modal-popup")
    leave(this.backdropTarget, "modal-backdrop").then(() => {
      this.element.remove()
    })
  }
}