import { Controller} from "@hotwired/stimulus"
let groupBy = require('lodash.groupby');
let forEach = require('lodash.foreach');

export default class extends Controller {
  static targets = [ "unit" ]

  connect() {
    this.unitTargets.forEach(task => task.querySelector('a').attr("data-").remove('active'))

    if (!this.element.classList.contains("isGrouped") && !document.documentElement.hasAttribute("data-turbo-preview")) {
      this.groupTasksByContext()
      this.element.classList.add("isGrouped")
    }
  }

  groupTasksByContext() {
    forEach(
      groupBy(this.element.querySelectorAll("article[class*=task]"), function(el) {
        return el.dataset.item;
      }),
      function (tasks, context) {
        let taskGroupHeader = document.createElement("h4")
        
        taskGroupHeader.innerHTML = tasks[0].querySelector("div[data-task-target='item']").innerHTML
        taskGroupHeader.classList.add("text-sm", "mb-0")
        taskGroupHeader.querySelector("a").classList.add("inline-block", "bg-gray-100", "text-gray-600", "mx-4", "md:mx-6", "my-2", "py-1", "px-3", "rounded-md", "hover:text-gray-800", "hover:bg-gray-200")
        
        tasks[0].insertAdjacentElement("beforebegin", taskGroupHeader)

        let spacer = document.createElement("div")
        spacer.classList.add("mb-6")
        tasks[tasks.length - 1].insertAdjacentElement("afterend", spacer)
      }
    )
  }
}