import { Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['input']

  connect() {
    new autoComplete({
      debounce: 300,
      searchEngine: "loose",
      selector: `#${this.inputTarget.id}`,
      threshold: 2,
  
      data: {
        src: async () => {
          const response = await get(`${this.data.get('url')}?type=Client&term=${this.inputTarget.value}`, { responseKind: "json" });
          if (response.ok) {
            const data = await response.json;
            return data;
          };
        },
        keys: ['name'],
        cache: false
      },
      
      resultsList: {
        maxResults: 5,
      },
      
      resultItem: {
        highlight: true,
      },

      events: {
        input: {
          selection: (event) => {
            Turbo.visit(event.detail.selection.value.location + "/lead/new?source=autocomplete")
          }
        }
      }
    });
  }
}