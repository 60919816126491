import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {}

  toggle(e) {
    if (e.target.options[e.target.selectedIndex].value == "") {
      this.contentTargets.forEach(el => el.classList.add("hidden"))
      this.contentTarget.querySelectorAll("select").forEach(select => select.setAttribute("disabled", true))
      this.contentTarget.querySelectorAll("input").forEach(input => input.setAttribute("disabled", true))
    } else {
      this.contentTargets.forEach(el => el.classList.remove("hidden"))
      this.contentTarget.querySelectorAll("select").forEach(select => select.removeAttribute("disabled"))
      this.contentTarget.querySelectorAll("input").forEach(input => input.removeAttribute("disabled"))
    }
  }
}