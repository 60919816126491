import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["valueInput", "searchInput"]
  static values = { src: String }

  connect() { 
    new autoComplete({
      debounce: 300,
      searchEngine: "loose",
      selector: `input#${this.searchInputTarget.id}[type="text"]`,
      threshold: 2,
      
      data: {
        src: async () => {
          const response = await get(`${this.srcValue}?term=${encodeURI(this.searchInputTarget.value)}`);
          if (response.ok) {
            const data = await response.json;
            return data;
          };
        },
        keys: ["label"],
        cache: false
      },

      resultsList: {
        maxResults: 10,
      },

      resultItem: {
        highlight: true
      },

      events: {
        input: {
          selection: (event) => {
            const hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', this.searchInputTarget.name);
            hiddenInput.setAttribute('value', event.detail.selection.value.value);
            hiddenInput.setAttribute('data-field--autocomplete-target', 'valueInput');
            
            this.element.prepend(hiddenInput)

            this.searchInputTarget.value = event.detail.selection.value.label
            this.searchInputTarget.setAttribute("disabled", true)
          }
        }
      }
    });
  }

  clear() {
    this.valueInputTarget.remove()
    this.searchInputTarget.disabled = false
    this.searchInputTarget.value = ""
    this.searchInputTarget.focus()
  }
}